<template>
  <div class="scanTopay">
    <img :src="wx" alt="" />
    <div class="tip">扫码支付</div>
    <div id="qrCode" ref="qrCode"></div>
    <div class="text">使用手机微信扫码完成付款</div>
    <div class="company">请在30分钟内完成支付，否则可能导致购买失败</div>
    <div class="price">¥{{ Number($route.query.price).toFixed(2) }}</div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import http from "@/utils/request";
import { onUnmounted, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessageBox } from "element-plus";

export default {
  setup() {
    let timer = null;
    let time = 0;
    const route = useRoute();
    const router = useRouter();
    function setTimer() {
      timer = setInterval(async () => {
        time += 3;
        const params = { orderCode: route.query.orderCode };
        const res = await http("/hcp/trade/tradeOrder/h5Status", { params });
        const isAddword = route.query.orderType === "addword";
        if (res.orderStatus === 2) {
          clearInterval(timer);
          isAddword
            ? router.push(`/writing`)
            : router.push(`result?orderCode=${route.query.orderCode}`);
        } else if (res.data.status === 600396) {
          clearInterval(timer);
          ElMessageBox.confirm("当前订单正在支付中，请稍后重试！", "提示", {
            confirmButtonText: "是",
            cancelButtonText: "否",
            type: "warning"
          }).then(async () => {
            setTimer();
          });
        }
        if (time > 60 * 30) {
          clearInterval(timer);
        }
      }, 3000);
    }
    onMounted(() => {
      if (timer) {
        clearInterval(timer);
      }
      setTimer();
    });
    onUnmounted(() => {
      console.log("卸载");
      clearInterval(timer);
    });
    return {
      timer,
      time,
      setTimer
    };
  },
  data() {
    return {
      wx: require("../../assets/img/wx_icon.png")
    };
  },
  mounted() {
    this.createCode();
  },
  methods: {
    createCode() {
      const qrcode = new QRCode("qrCode", {
        width: 270,
        height: 270,
        text: sessionStorage.getItem("codeUrl")
      });
      console.log(qrcode);
    }
  }
};
</script>

<style lang="scss" scoped>
.scanTopay {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 12px;
  height: 800px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    width: 120px;
    height: 120px;
  }
  .tip {
    font-size: 26px;
    margin: 20px 0;
  }
  .text {
    margin-top: 10px;
    color: #a6aabb;
  }
  .company {
    margin: 30px 0;
    color: #a6aabb;
  }
  .price {
    font-size: 40px;
    color: #3377ff;
  }
}
</style>
